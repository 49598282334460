import React, { FC } from "react";
import PdpBuilder from "../PdpBuilder";
import MainImageSlider from "../MainImageSlider";
import useBuildOwn from "./useBuildOwn";
import { PropsFromScreenManager } from "../../types";
import classes from "./BuildOwn.module.css";
import Header from "../Header/Header";
import StickyFooter from "../StickyFooter/StickyFooter";
import { RootState } from "../../reducers/RootReducer";
import { connect, ConnectedProps } from "react-redux";
import {
  setCartImageSuccess,
  setCartThumbImageSuccess,
} from "../../action/CartAction";
import { setIsEngraving, setIsHandLoaded } from "../../action/RingAction";

export const iFrameHost: string = localStorage.getItem("dev3D")
  ? "http://localhost:5173"
  : (process.env.REACT_APP_3D_FRAME_URL as string);
const BuildOwn: FC<PropsFromScreenManager & PropsFromCartRedux> = (props) => {
  const { setIsEngraving, instanceData, ring, setIsHandLoaded } = props;
  const {
    img,
    thumbImage,
    isIcon,
    viewSrc,
    iframeLoadActionCount,
    activeIndex,
    setActiveIndex,
    setIframeLoadActionCount,
    islargeTab,
    isSticky,
    byoSliderRoot,
    urlData: { command, params },
  } = useBuildOwn(props);

  if (
    (command?.toLowerCase() === "DYO"?.toLowerCase() &&
      !params.styleId.toLowerCase().includes("RB".toLowerCase())) ||
    (command?.toLowerCase() === "SETTING"?.toLowerCase() &&
      !params.styleId.toLowerCase().includes("RP".toLowerCase()))
  ) {
    return <div>Something went wrong.</div>;
  }
  return (
    <>
      <Header
        instanceData={instanceData}
        iframeLoadActionCount={iframeLoadActionCount}
      />
      <main
        className={`${classes.root} ${isSticky ? "stickySlider" : ""}`}
        ref={byoSliderRoot}
      >
        <div className={classes.container}>
          {islargeTab ? <div className={"slider-wrapper-stick"}></div> : null}
          <div className={classes.slider}>
            <div className={`${classes.viewRoot} sliderRef`}>
              <MainImageSlider
                viewSrc={viewSrc}
                img={img}
                setIsHandLoaded={setIsHandLoaded}
                isHandLoaded={ring.isHandLoaded}
                isHandImage={true}
                thumbImage={thumbImage}
                isIcon={isIcon}
                is360={true}
                iframeLoadActionCount={iframeLoadActionCount}
                isEngraving={ring.isEngraving}
                setIsEngraving={setIsEngraving}
                setThumbActiveIndex={setActiveIndex}
              />
            </div>
          </div>
          <div className={classes.detail}>
            <PdpBuilder
              instanceData={instanceData}
              iframeLoadActionCount={iframeLoadActionCount}
              setIframeLoadActionCount={setIframeLoadActionCount}
              activeIndex={activeIndex}
            />
          </div>
        </div>
      </main>
      <StickyFooter instanceData={instanceData} />
    </>
  );
};
const mapStateToProps = (state: RootState) => ({
  ring: state.ring,
  footer: state.footer,
});

const mapDispatchToProps = {
  setCartImageSuccess,
  setCartThumbImageSuccess,
  setIsHandLoaded,
  setIsEngraving,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type PropsFromCartRedux = ConnectedProps<typeof connector>;
export default connector(BuildOwn);
