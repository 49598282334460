import React, { FC } from "react";
import classes from "./Loader.module.css";
import { LoaderProps } from "../../types";
import ajaxLoader from "./iframe-loader.gif";

const Loader: React.FC<LoaderProps> = ({
  position,
  ajaxLoad = false,
  className = "",
  ...restProps
}) => {
  const divStyle: React.CSSProperties = {
    position,
  };
  const positionType = position === "fixed" ? "fixed" : "absolute";
  return (
    <div
      className={`${classes.root} ${positionType} loading-root ${className}`}
      {...restProps}
    >
      {ajaxLoad ? (
        <img
          className={classes.loaderAjax}
          src={ajaxLoader}
          alt={"Loading..."}
        />
      ) : (
        <div className={classes.loader} />
      )}
    </div>
  );
};

export default Loader;
