import React, { FC } from 'react';
import { ButtonProps } from '../../types';
import Loader from '../Loader/Loader';
import classes from './Button.module.css';

const Button: FC<ButtonProps> = (props) => {
  const {
    type,
    buttonType,
    children,
    className,
    onClick,
    isDisabled,
    name,
    isLoading = false,
    code,
    ...rest
  } = props;
  return (
    <>
      <button
        {...rest}
        type={type}
        onClick={onClick}
        name={name}
        data-code={code}
        disabled={isDisabled}
        className={`${classes.root} ${buttonType ? classes[buttonType] : ""}  ${
          isDisabled ? classes.isDisabled : ""
        } ${className} `}
      >
        {isLoading && <Loader className={classes.loader} />}
        {children}
      </button>
    </>
  );
};

export default Button;
