import React, { memo, useMemo, useRef } from "react";
import {
  useVirtualizer,
  VirtualItem,
  Virtualizer,
} from "@tanstack/react-virtual";
import { Memo_MRT_TableBodyRow, MRT_TableBodyRow } from "./MRT_TableBodyRow";
import { MRT_RowData, MRT_TableInstance } from "../../types";
import { rankGlobalFuzzy } from "../../sortingFns";
import Button from "../../../../../Button";

interface Props<TData extends MRT_RowData> {
  columnVirtualizer?: Virtualizer<HTMLDivElement, HTMLTableCellElement>;
  table: MRT_TableInstance<TData>;
  virtualColumns?: VirtualItem[];
  virtualPaddingLeft?: number;
  virtualPaddingRight?: number;
}

export const MRT_TableBody = <TData extends MRT_RowData>({
  columnVirtualizer,
  table,
  virtualColumns,
  virtualPaddingLeft,
  virtualPaddingRight,
}: Props<TData>) => {
  const {
    getRowModel,
    getPrePaginationRowModel,
    getState,
    options: {
      enableGlobalFilterRankedResults,
      enablePagination,
      enableRowVirtualization,
      layoutMode,
      localization,
      manualExpanding,
      manualFiltering,
      manualGrouping,
      manualPagination,
      manualSorting,
      memoMode,
      muiTableBodyProps,
      renderEmptyRowsFallback,
      rowVirtualizerInstanceRef,
      rowVirtualizerOptions,
      rowCount,
    },
    refs: { tableContainerRef, tablePaperRef },
  } = table;

  const {
    columnFilters,
    density,
    expanded,
    globalFilter,
    globalFilterFn,
    pagination,
    sorting,
    activeTab,
    grouping,
  } = getState();

  const tableBodyProps =
    muiTableBodyProps instanceof Function
      ? muiTableBodyProps({ table })
      : muiTableBodyProps;

  const vProps =
    rowVirtualizerOptions instanceof Function
      ? rowVirtualizerOptions({ table })
      : rowVirtualizerOptions;

  const shouldRankResults = useMemo(
    () =>
      !manualExpanding &&
      !manualFiltering &&
      !manualGrouping &&
      !manualSorting &&
      enableGlobalFilterRankedResults &&
      globalFilter &&
      globalFilterFn === "fuzzy" &&
      expanded !== true &&
      !Object.values(sorting).some(Boolean) &&
      !Object.values(expanded).some(Boolean),
    [
      enableGlobalFilterRankedResults,
      expanded,
      globalFilter,
      manualExpanding,
      manualFiltering,
      manualGrouping,
      manualSorting,
      sorting,
    ]
  );
  function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ");
  }

  const rows = useMemo(() => {
    if (!shouldRankResults) return getRowModel().rows;
    const rankedRows = getPrePaginationRowModel().rows.sort((a, b) =>
      rankGlobalFuzzy(a, b)
    );
    if (enablePagination && !manualPagination) {
      const start = pagination.pageIndex * pagination.pageSize;
      return rankedRows.slice(start, start + pagination.pageSize);
    }
    return rankedRows;
  }, [
    shouldRankResults,
    shouldRankResults ? getPrePaginationRowModel().rows : getRowModel().rows,
    pagination.pageIndex,
    pagination.pageSize,
  ]);

  const UseVirtualizer = useVirtualizer({
    count: rows.length,
    estimateSize: () =>
      density === "compact" ? 37 : density === "comfortable" ? 58 : 73,
    getScrollElement: () => tableContainerRef.current,
    measureElement:
      typeof window !== "undefined" &&
      navigator.userAgent.indexOf("Firefox") === -1
        ? (element) => element?.getBoundingClientRect().height
        : undefined,
    overscan: 4,
    ...vProps,
  });
  const rowVirtualizer = enableRowVirtualization ? UseVirtualizer : undefined;

  if (rowVirtualizerInstanceRef && rowVirtualizer) {
    rowVirtualizerInstanceRef.current = rowVirtualizer;
  }

  const virtualRows = rowVirtualizer
    ? rowVirtualizer.getVirtualItems()
    : undefined;
  const bodyRef = useRef();

  const handleResetFilter = () => {
    document.getElementById("reset-filter")?.click();
  };

  return (
    <>
      <tbody
        ref={bodyRef}
        className={classNames(
          "headless_tbody",
          layoutMode === "grid" ? "headless_grid" : "headless_table-row-group",
          !rows.length ? `headless_min-h-100` : ``
        )}
        {...tableBodyProps}
        style={{
          height: rowVirtualizer
            ? `${rowVirtualizer.getTotalSize()}px`
            : "inherit",
        }}
      >
        {tableBodyProps?.children ?? (
          <>
            {!rows.length || (activeTab === 0 && !+(rowCount ?? 0)) ? (
              <tr
                style={{
                  display:
                    layoutMode === "grid"
                      ? "headless_grid"
                      : "headless_table-row",
                }}
              >
                <td
                  colSpan={table.getVisibleLeafColumns().length}
                  style={{
                    display:
                      layoutMode === "grid"
                        ? "headless_grid"
                        : "headless_table-cell",
                  }}
                >
                  {renderEmptyRowsFallback?.({ table }) ?? (
                    <div
                      className={`headless_td-empty-rows ${
                        activeTab === 0 &&
                        grouping.length > 0 &&
                        !+(rowCount ?? 0)
                          ? "headless_td-empty-rows_first_tab"
                          : ""
                      }`}
                      /* style={{
                      maxWidth:
                        `100vw` +
                        `${tablePaperRef.current?.clientWidth ?? 360}px`,
                    }}*/
                    >
                      {activeTab !== 1 ? (
                        globalFilter || columnFilters.length ? (
                          localization.noResultsFound
                        ) : activeTab === 0 && !+(rowCount ?? 0) ? (
                          <div className={"headless_empty_row"}>
                            <div className={"headless_empty_row_title"}>
                              We're sorry, we couldn't find exact matches for
                              your filters.
                            </div>
                            <Button
                              type={"button"}
                              buttonType={"btnPrimary"}
                              className={"headless_reset_filter_btn"}
                              onClick={handleResetFilter}
                            >
                              Reset All Filters
                            </Button>
                            {rows.length ? <div className="headless_empty_row_subtitle">
                              Here are the closet options we have to offer.
                            </div> : null}
                          </div>
                        ) : (
                          "No diamonds match your criteria"
                        )
                      ) : (
                        "Select your preferred diamond shape, type and carat size to see a curated selection of diamonds with various prices, color and clarity to perfectly complete your ring."
                      )}
                    </div>
                  )}
                </td>
              </tr>
            ) : null}
            <>
              {rows.length
                ? (virtualRows ?? rows).map((rowOrVirtualRow, rowIndex) => {
                    const row = rowVirtualizer
                      ? rows[rowOrVirtualRow.index]
                      : rowOrVirtualRow;
                    const props = {
                      columnVirtualizer,
                      measureElement: rowVirtualizer?.measureElement,
                      numRows: rows.length,
                      rowIndex: rowVirtualizer
                        ? rowOrVirtualRow.index
                        : rowIndex,
                      table,
                      row,
                      virtualColumns,
                      virtualPaddingLeft,
                      virtualPaddingRight,
                      virtualRow: rowVirtualizer ? rowOrVirtualRow : undefined,
                    };
                    return memoMode === "rows" ? (
                      <Memo_MRT_TableBodyRow key={rowIndex} {...props} />
                    ) : (
                      <MRT_TableBodyRow
                        key={rowIndex}
                        {...props}
                        bodyRef={bodyRef}
                      />
                    );
                  })
                : null}
            </>
          </>
        )}
      </tbody>
    </>
  );
};

export const Memo_MRT_TableBody = memo(
  MRT_TableBody,
  (prev, next) => prev.table.options.data === next.table.options.data
) as typeof MRT_TableBody;
