import general from "./general";

export const ringSettingParams:Record<string, string> =  {
    Ring_Style: general.RING_STYLE,
    Diamond_Shape: general.SHAPE,
    Diamond_Type: general.TYPE,
    Center_Diamond_Size:general.CARAT,
    Ring_Crown: general.CROWN,
    Ring_Side:general.SIDE,
    Metal_Color:general.METAL,
    Crown_Metal_Color: general.CROWN_METAL,
    Ring_Size: general.SIZE,
    Wedding_Band: general.BAND
}