class RouteManager {
  static findPath(route: string, styleId = false) {
    switch (true) {
      case route === "#home":
        return "./components/CMSPage/CMSScreen";
      case route === "#edit":
        return "./components/StickyFooter/StickyFooterScreen";
      case route && route.startsWith("#details"):
        return "./components/DiamondDetailsPage/DiamondDetailsScreen";
      case route === "#diamond" || route === "#select-diamond":
        return "./components/Diamond/DiamondScreen";
      case route === '#setting' && !styleId:
        return "./components/StartWithSetting/StartWithSettingScreen";
      case route === "#store":
        return "./components/Store/StoreScreen";
      case route === "#dyo":
      case route === '#setting' && styleId:
        return "./components/BuildOwn/BuildOwnScreen";
      default:
        return "./components/CMSPage/CMSScreen";
    }
  }
}

export default RouteManager;
