export default {
  BUILDER_MODE_KEY: 'builder_mode',
  DEFAULT_RPID: '000000',
  EDIT: 'edit',
  BUILD: 'build',
  DIAMOND: 'diamond',
  SETTING: 'setting',
  DYO: 'dyo',
  DYO_STYLE_ID : 'RB001',
  RING_STYLE: 'ring_style',
  SHAPE: 'shape',
  TYPE: 'type',
  CARAT: 'carat',
  CROWN: 'crown',
  SIDE: 'side',
  METAL: 'metal',
  CROWN_METAL: 'crown_metal',
  SIZE: 'size',
  BAND: 'band',
};
