import React, { FC } from 'react';
import Tab from './Tab';
import classes from './Tabs.module.css';
import TabContent from './TabContent';
import { TabsProps, TabsPropsRedux } from '../../types';
import { RootState } from '../../reducers/RootReducer';
import { setActiveTab } from '../../action/TableAction';
import { connect, ConnectedProps } from 'react-redux';

const mapStateToProps = (state: RootState) => ({
  table: state.table,
});

const mapDispatchToProps = {
  setActiveTab,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PropsFromTabsRedux = ConnectedProps<typeof connector>;

const Tabs: FC<TabsPropsRedux> = ({ setActiveTab, table, tabs }) => {
  const handleTabClick = (tabIndex: number) => {
    setActiveTab(tabIndex);
  };

  return (
    <>
      <div className={classes.root}>
        <nav className={classes.nav} aria-label="Tabs" id={"tabNavigation"}>
          {tabs &&
            tabs.length > 0 &&
            tabs.map((tab: TabsProps, index: number) => {
              return (
                <Tab
                  label={tab.label}
                  onClick={() => handleTabClick(index)}
                  isActive={table.activeTab === index}
                  key={index}
                  isIcon={tab.isIcon}
                  iconName={tab.iconName ? tab.iconName : ""}
                  isNotShow={tab.isNotShow}
                />
              );
            })}
        </nav>
      </div>
      {tabs.length > 0 && (
        <>
          {(table.activeTab === 0 && !tabs[table.activeTab].isDataNotAvailable) && (
            <TabContent content={tabs[table.activeTab].content} />
          )}
          {(table.activeTab === 0 && tabs[table.activeTab].isDataNotAvailable) && (
            <TabContent content={tabs[1]?.content} />
          )}
          {(table.activeTab === 1 || table.activeTab === 2 || table.activeTab === 3) && (
            <TabContent content={tabs[table.activeTab]?.content} />
          )}
        </>
      )}
    </>
  );
};
export default connector(Tabs);
